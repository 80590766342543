import { template as template_e16fd44efd4945e3a61d5b0f9c1a80db } from "@ember/template-compiler";
const FKLabel = template_e16fd44efd4945e3a61d5b0f9c1a80db(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
