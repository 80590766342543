import { template as template_2f5a6bcd469449c6a54f5b05f0fb1d5e } from "@ember/template-compiler";
const WelcomeHeader = template_2f5a6bcd469449c6a54f5b05f0fb1d5e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
