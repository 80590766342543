import { template as template_42e3b5379157415b8b6e80833a14257c } from "@ember/template-compiler";
const FKControlMenuContainer = template_42e3b5379157415b8b6e80833a14257c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
